
import {computed, defineComponent, onUpdated, watch} from "vue";
import {useGroupStore} from "@/store/group.store";
import {PinaLoadEntity} from "@/core/composite/composite";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import TeamForm from "@/views/team/TeamForm.vue";
import Swal from "sweetalert2";
import TeamService from "@/core/services/GroupService";
import router from "@/router";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import VerticalEntity from "@/components/common/VerticalEntity.vue";
import {getCurrentCompany} from "@/core/services/JwtService";
import store from "@/store";

export default defineComponent({
  name: "Team",
  components: {VerticalEntity, TabNavigate, TeamForm, BaseModal},
  setup() {
    const teamStore = useGroupStore();
    const entity = computed<any>(() => teamStore.entity);
    const companies = computed<any>(() => store.getters.currentUser.companies);
    const selectedCompany = (companies) => {
      const id = getCurrentCompany();
      return companies.value?.find(item => item.id === id)?.name
    }
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Team', [
        {link: true, router: '/company/' + getCurrentCompany() + "/settings", text: selectedCompany(companies)},
        {link: false, router: '', text: entity.value.name}
      ])
    })

    watch(entity, () => {
      setCurrentPageBreadcrumbsWithParams('Team', [
        {link: true, router: '/company/' + getCurrentCompany() + "/settings", text: selectedCompany(companies)},
        {link: false, router: '', text: entity.value.name}
      ])
    })

    return {
      ...PinaLoadEntity(teamStore),
      entity,
    }
  },
  methods: {
    onEdit() {
      const modal = this.$refs.formTeamEditref as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      this.loadEntity();
      const modal = this.$refs.formTeamEditref as typeof BaseModal;
      modal.hideBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await TeamService.delete(id).then(() => {
            router.push({name: 'teams'})
          })
        }
      })
    }
  }
})
